    @import "styles/variables";
    @import "styles/mixins";

$story-container-max-width: 926px;

.story {
    padding: $spacing-5 $spacing-3 0;
}

.container {
    margin: 0 auto;
    padding-top: $spacing-6;

    @include desktop-up {
        max-width: $story-container-max-width;
        padding-top: $spacing-9;
    }

    &.withImage {
        padding-top: $spacing-5;

        @include desktop-up {
            padding-top: 0;
        }
    }
}

.meta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: pre;
    margin-bottom: $spacing-4;
}

.categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.categoryLink {
    background-color: var(--prezly-accent-color);
    color: $color-base-white;
    margin-bottom: $spacing-1;

    &:hover {
        background-color: var(--prezly-accent-color-lightest);
    }

    &:active {
        background-color: var(--prezly-accent-color-darkest);
    }
}

.date {
    @include text-small;

    margin: $spacing-4 0;
    color: $color-base-400;
    text-transform: uppercase;

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }

    &.center {
        text-align: center;
    }
}
