    @import "styles/variables";
    @import "styles/mixins";

.container {
    padding-top: $spacing-6;

    @include not-desktop {
        padding-top: $spacing-5;
    }
}

.loadMore {
    display: flex !important;
    margin: $spacing-8 auto 0;
}
