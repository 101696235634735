    @import "styles/variables";
    @import "styles/mixins";

.alignLeft {
    text-align: left;
}

.alignCenter {
    text-align: center;
}

.alignRight {
    text-align: right;
}

.headingOne,
.headingTwo,
.paragraph,
.htmlContent p,
.htmlContent h1,
.htmlContent h2 {
    color: $color-base-700;
}

.headingOne,
.htmlContent h1 {
    @include heading-2;

    @include desktop-up {
        @include heading-1;
    }

    /* stylelint-disable-next-line order/order */
    margin: $spacing-6 auto $spacing-3;

    strong {
        font-weight: $font-weight-bolder;
    }
}

.headingTwo,
.htmlContent h2 {
    @include heading-3;

    @include desktop-up {
        @include heading-2;
    }

    /* stylelint-disable-next-line order/order */
    margin: $spacing-6 auto $spacing-3;

    /* stylelint-disable-next-line no-descending-specificity */
    strong {
        font-weight: $font-weight-bolder;
    }
}

.headingThree,
.htmlContent h3 {
    @include heading-3;

    margin: $spacing-6 auto $spacing-3;
}

.paragraph,
.htmlContent p {
    margin: 0 auto $spacing-2;

    /* stylelint-disable-next-line no-descending-specificity */
    strong {
        font-weight: $font-weight-bold;
    }
}

.link,
.htmlContent a {
    @include link-primary;
}

.bulletedList,
.numberedList,
.htmlContent ul,
.htmlContent ol {
    margin: 0 auto $spacing-4;
    padding-left: $spacing-5;
}

.numberedList,
.htmlContent ol {
    list-style-type: decimal;

    ol {
        list-style-type: lower-latin;
    }

    // stylelint-disable-next-line selector-max-compound-selectors
    ol ol {
        list-style-type: lower-roman;
    }

    // stylelint-disable-next-line selector-max-compound-selectors
    ol ol ol {
        list-style-type: decimal;
    }
}

.bulletedList,
.numberedList {
    /**
     * Note: There's a fourth case of alignment, when it's undefined.
     * Which means it should inherit alignment from the document.
     * This is important, as with default alignment we shouldn't
     * apply `list-style-position` rule to make the bullets look good.
     */
    &.alignLeft,
    &.alignCenter,
    &.alignRight {
        padding-left: 0;
        padding-right: 0;
        list-style-position: inside;
    }
}

.listContainer {
    display: flex;

    .numberedList,
    .bulletedList {
        margin-left: 0;
        margin-right: 0;
    }

    &.alignLeft {
        text-align: left;
        justify-content: flex-start;
    }

    &.alignCenter {
        text-align: left;
        justify-content: center;
    }

    &.alignRight {
        text-align: right;
        justify-content: flex-end;
    }
}

.listItem,
.htmlContent li {
    @include paragraph;
}

.htmlContent table {
    width: 100% !important; // need to be specified because there is a default style from renderer
    text-indent: 0;
    border-collapse: collapse;
    border-color: inherit;
}

.htmlContent blockquote {
    @include border-radius-m;

    position: relative;
    margin: $spacing-6 auto;
    padding: $spacing-4 $spacing-6;
    background: $color-base-50;

    &::before {
        content: "";
        border-left: 2px solid $color-base-400;
        padding-left: $spacing-4;
        position: absolute;
        top: $spacing-3;
        bottom: $spacing-3;
        left: $spacing-3;
    }
}
