    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include border-radius-s;

    display: flex;
    margin: $spacing-6 auto;
    padding: $spacing-4;
    background: $color-base-50;
    border: 1px solid $color-borders;
    text-decoration: none;
    color: inherit;

    @include mobile-only {
        flex-direction: column;
    }
}

.icon {
    width: 2.5rem;
    height: 2.5rem;
    flex-shrink: 0;
    place-self: center;
    margin-right: $spacing-3;

    @include mobile-only {
        display: none;
    }
}

.content {
    flex: 1;
    overflow: hidden;
}

.name {
    @include heading-3;

    margin: 0 auto $spacing-half;
    color: $color-text;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.type {
    @include text-label;

    margin: 0 auto;
    color: $color-base-500;
    font-weight: $font-weight-regular;
}

.downloadLink {
    align-self: center;
    margin-left: $spacing-3;

    @include mobile-only {
        align-self: flex-start;
        margin-left: 0;
        margin-top: $spacing-3;
    }

    .container:hover & {
        color: var(--prezly-accent-color);
    }
}
