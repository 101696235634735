    @import "styles/variables";
    @import "styles/mixins";

.container {
    padding-top: $spacing-6;

    @include desktop-up {
        padding-top: $spacing-8;
    }
}
