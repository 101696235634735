    @import "styles/variables";
    @import "styles/mixins";

$story-image-height: 180px;

.container {
    position: relative;
    isolation: isolate;
    border: 1px solid $color-borders;
    background-color: $color-base-white;
    border-radius: $border-radius-lg;
    transform: translateY(0);
    box-shadow: 0 0 0 0 rgba(0 0 0 / 0%);
    transition: all 0.5s ease-in-out;

    &::after {
        @include shadow-xxl;

        content: "";
        border-radius: $border-radius-lg;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.5s ease-in-out;
    }

    @media (hover: hover) {
        &:hover {
            transform: translateY(-$spacing-2);
        }

        &:hover::after {
            opacity: 1;
        }
    }
}

.imageWrapper {
    display: block;
    overflow: hidden;
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
    height: $story-image-height;
}

.placeholder,
.image {
    height: $story-image-height;
}

.content {
    padding: $spacing-3 $spacing-3;
    display: flex;
    flex-direction: column;
    height: calc(100% - $story-image-height);
}

.categories {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacing-1;
}

.title {
    @include heading-3;
    @include ensure-max-text-height(3, $line-height-l);

    font-weight: $font-weight-bold;
    margin-top: 0;
    margin-bottom: $spacing-2;

    &.extendedTitle {
        @include ensure-max-text-height(4, $line-height-l);
    }
}

.titleLink {
    text-decoration: none;
    color: inherit;
}

.linkMask {
    position: absolute;
    inset: 0;
}

.subtitle {
    @include text-label;
    @include ensure-max-text-height(2, $line-height-m);

    margin: 0 0 $spacing-2;
    font-weight: $font-weight-regular;
    color: $color-base-600;
    text-align: left;
}

.date {
    @include text-small;

    margin: auto 0 0;
    color: $color-base-400;
}

.categoryLink {
    position: relative;
    z-index: 1;
    background-color: var(--prezly-accent-color);
    color: $color-base-white;
    transition: background-color 0.15s ease-in;

    &:hover {
        background-color: var(--prezly-accent-color-lightest);
    }

    &:active {
        background-color: var(--prezly-accent-color-darkest);
    }
}
