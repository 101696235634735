    @import "styles/variables";
    @import "styles/mixins";

.title {
    display: block;
    color: $color-base-600;
    font-weight: 500;
}

.description {
    @include text-small;
    @include line-clamp(2);

    display: block;
    margin-top: $spacing-1;
    color: $color-base-500;
    font-weight: $font-weight-regular;

    @include mobile-only {
        display: none;
    }
}
