    @import "styles/variables";
    @import "styles/mixins";

.link {
    @include text-small;
    @include link-dark;

    padding: $spacing-small $spacing-1;
    background-color: $color-base-100;
    border-radius: $border-radius-m;
    font-weight: $font-weight-bold;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: $color-base-200;
    }

    &:not(:last-child) {
        margin-right: 0.625rem;
    }
}
