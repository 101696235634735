    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
    position: relative;
}

.input,
.message {
    color: $color-text;

    @include desktop-up {
        @include shadow-inset;

        padding: $spacing-1 $spacing-2;
        border: 1px solid $color-borders;
        border-radius: $border-radius-s;
    }
}

.input {
    @include not-desktop {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        pointer-events: none;
    }
}

.paste {
    font-size: $font-size-xs;
    background: $color-base-white;
    height: $spacing-6;
    width: $spacing-6;
    align-items: center;
    justify-content: center;
}

.icon {
    width: $spacing-3;
    height: $spacing-3;
    margin-top: $spacing-half;
}

.message {
    position: absolute;
    transition: opacity 0.4s ease;
    will-change: opacity;

    @include not-desktop {
        inset: 0 0 0 -10.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-base-white;
        color: $color-base-500;
    }

    @include desktop-up {
        position: absolute;
        top: 0;
        left: -150px;
        width: 200px;
        height: 100%;
        background: $color-base-white;
        display: flex;
        text-align: center;
        justify-content: center;
    }
}

.transitionStart {
    opacity: 0;
}

.transitionFinish {
    opacity: 1;
}
