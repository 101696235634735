    @import "styles/variables";
    @import "styles/mixins";

.avatar {
    width: 4rem;
    height: 4rem;
    margin-right: $spacing-3;
    border-radius: $border-radius-s;
}

.content {
    display: flex;

    .meta {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.name {
    @include paragraph;

    margin: 0;
}

.position {
    @include text-small;

    margin: 0;
    color: $color-base-500;
    font-weight: $font-weight-regular;
}

.links {
    display: flex;
    flex-direction: column;
}

.linkGroup {
    display: flex;
    gap: $spacing-3;
    flex-wrap: wrap;

    &:not(:last-child) {
        margin-bottom: $spacing-4;
    }

    .signature &:not(.social) {
        flex-direction: column;
        gap: $spacing-1;
    }
}

.link {
    @include text-label;
    @include link-primary;

    display: flex;
    align-items: center;
    gap: $spacing-1;
    overflow: hidden;
}

.linkText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.icon {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
}

.container {
    overflow: hidden;

    &.card {
        @include border-radius-lg;

        padding: $spacing-4;
        background: $color-base-white;
        border: 1px solid $color-borders;
    }
}

.divider {
    border: 1px solid $color-borders;

    .card & {
        margin: $spacing-4 0;
    }

    .signature & {
        margin: $spacing-3 0;
    }
}
