    @import "styles/variables";
    @import "styles/mixins";

.container {
    padding: $spacing-9 0 0;
    color: $color-base-white;
    background: $color-base-800;
}

.columns {
    display: block;

    @include desktop-up {
        display: flex;
    }
}

.heading {
    @include paragraph;

    font-weight: 700;
    margin: $spacing-3 0;
    color: $color-base-white;
}

.aboutUs {
    @include text-small;

    flex-grow: 2;
    margin-bottom: $spacing-6;

    @include desktop-up {
        margin-bottom: 0;

        + .contacts {
            margin-left: $spacing-6;
        }
    }

    p {
        @include text-small;
    }
}

.contacts {
    @include tablet-up {
        min-width: 350px;
    }
}

.about {
    margin-bottom: $spacing-5;

    * {
        color: inherit;
    }
}

.socialMedia {
    margin-top: $spacing-6;
}

.linkWrapper {
    display: flex;
    align-items: center;

    p {
        font-size: $font-size-xs;
        margin: $spacing-half;
        white-space: pre;
    }

    .icon {
        $size: 14px;

        align-self: flex-start;
        height: $size;
        width: $size;
        margin: 0.675rem $spacing-1 0 0;
    }
}

.link,
.about a {
    @include link-white;

    text-decoration: none;
    font-size: $font-size-xs;
}

.separator {
    width: 1.5rem;
    height: 0.125rem;
    background: $color-base-white;
}
