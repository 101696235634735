    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include border-radius-lg;

    min-height: 362px;
    border: 1px solid $color-borders;
}

.thumbnailWrapper {
    position: relative;
}

.thumbnail {
    width: 100%;
    margin: 0 auto;
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
    object-fit: cover;
}

.title {
    @include heading-2;

    color: $color-base-800;
    margin: 0;
    padding: 0;

    &:hover,
    &:focus {
        color: $color-base-500;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $spacing-2 $spacing-4;
}

.button {
    @include text-small;

    font-weight: 700;
    padding: $spacing-1;
    border-radius: $border-radius-lg;
    color: $color-base-600;
    margin-top: $spacing-2;
}
