    @import "styles/variables";
    @import "styles/mixins";

.error {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: $spacing-7 0;

    @include tablet-up {
        padding: $spacing-10 0;
    }
}

.button {
    color: $color-base-white;
}
