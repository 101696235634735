    @import "styles/variables";
    @import "styles/mixins";

.container {
    padding: $spacing-5 $spacing-3;
    background-color: $color-base-800;
    border-top: 1px solid $color-borders;
    text-align: center;

    @include tablet-up {
        padding: $spacing-8 $spacing-6;
    }
}

.content {
    @include container;

    margin-left: auto;
    margin-right: auto;
    background-color: $color-base-700;
    padding: $spacing-6 $spacing-4;
    border-radius: $border-radius-lg;

    @include tablet-up {
        max-width: calc(1280px - 10rem);
    }
}

.title {
    @include heading-1;

    color: $color-base-white;
    margin-top: 0;
    margin-bottom: $spacing-5;
}

.inlineForm {
    text-align: left;

    @include tablet-up {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: $spacing-2;
    }

    @include desktop-up {
        margin-left: auto;
        margin-right: auto;
        max-width: 520px;
    }
}

.button {
    width: 100%;
    align-self: start;
}

.input {
    @include tablet-up {
        margin-bottom: 0 !important;
    }

    input::placeholder {
        color: $color-base-600;
        font-weight: 400;
    }
}

.disclaimer {
    @include text-small;

    color: $color-base-400;
    margin-top: $spacing-3 + $spacing-half;
    margin-bottom: 0;
}

.disclaimerLink {
    font-weight: $font-weight-medium;
    color: $color-base-400;
    text-decoration: none;

    &:hover,
    &:focus {
        color: var(--prezly-accent-color-lightest);
    }
}
