    @import "styles/variables";
    @import "styles/mixins";

$more-button-width: 100px;

:export {
    /* stylelint-disable-next-line property-no-unknown */
    MORE_BUTTON_WIDTH: $more-button-width;
}

.wrapper {
    @include shadow-l;

    background-color: var(--prezly-header-background-color);
    color: var(--prezly-header-link-color);
    z-index: 1;

    @include mobile-only {
        display: none;
    }
}

.container {
    @include container;

    display: flex;
    align-items: center;
    padding-top: $spacing-half;
    padding-bottom: $spacing-half;
}

.link {
    font-size: $font-size-xs !important;
    color: var(--prezly-header-link-color) !important;
    white-space: nowrap;

    &.active {
        border-bottom: 2px solid var(--prezly-accent-color);
        border-radius: 0;
    }

    &:active {
        border-color: transparent;
    }

    &:first-child {
        margin-left: -$spacing-2;
    }
}

.more {
    font-size: $font-size-xs;
    color: var(--prezly-header-link-color);
    width: $more-button-width;
}

.dropdown {
    top: 50px;
    z-index: 10;
}
