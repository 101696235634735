    @import "styles/variables";
    @import "styles/mixins";

.highlightedStoriesContainer {
    margin-bottom: $spacing-6;

    @include not-desktop {
        margin-bottom: $spacing-5;
    }
}

.storiesContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spacing-6 $spacing-6;

    @media screen and (min-width: $breakpoint-cards-small) and (max-width: $breakpoint-cards-medium) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: ($breakpoint-cards-medium + 1px)) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include mobile-only {
        padding-right: $grid-spacing-mobile;
        padding-left: $grid-spacing-mobile;
    }
}

.noStories {
    padding: $spacing-10 0;
    margin: 0 auto;
    text-align: center;

    @include desktop-up {
        padding: 11rem 0;
    }
}

.noStoriesTitle {
    margin-top: $spacing-4;
    margin-bottom: $spacing-2;
}

.noStoriesSubtitle {
    color: $color-base-500;
    margin: 0;
}
